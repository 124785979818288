<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Edit Data Pengguna'" />
    <stepper-user :steps="steps" :role="role" />

    <div v-if="this.$route.params.role == 'lender'">
      <h4 class="pb-4">Form Lender</h4>
      <form-lender :formLender="formNewLender" />
      <!-- <button-form :submit="submitLender" :loading="loading" class="pt-8" /> -->
    </div>

    <div v-if="this.$route.params.role == 'borrower'">
      <h4 class="pb-4">Form Borrower</h4>
      <form-borrower :formBorrower="formNewBorrower" />
      <!-- <button-form :submit="submitBorrower" :loading="loading" class="pt-8" /> -->
    </div>
    <v-divider class="my-6" />

    <div>
      <h4 class="pb-4">Form Input</h4>
      <form-user :formUser="formNewUser" />
      <button-form :submit="submitUser" :loading="loading" class="pt-8" />
      <popup-role
        v-if="showDialog"
        :dialog="showDialog"
        :closeDialog="submitUser"
      />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormUser from "../../components/Form/FormUserEdit.vue";
import FormLender from "../../components/Form/FormLenderEdit.vue";
import FormBorrower from "../../components/Form/FormBorrowerEdit.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    PopupSuccess,
    PopupError,
    ButtonForm,
    FormUser,
    FormLender,
    FormBorrower,
  },

  data() {
    return {
      check: false,
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      formNewUser: {
        idPenyelenggara: "",
        idPenggunaRebel: "",
        idPengguna: "",
        namaPengguna: "",
        jenisPengguna: null,
        tanggalRegistrasi: "",
        jenisIdentitas: null,
        nomorIdentitas: "",
        nomorNpwp: "",
        jenisBadanHukum: null,
        tempatLahir: "",
        tanggalLahir: "",
        jenisKelamin: null,
        alamat: "",
        kota: null,
        provinsi: null,
        kodePos: "",
        agama: null,
        perkawinan: null,
        pekerjaan: null,
        bidangPekerjaan: null,
        pekerjaanOnline: null,
        pendapatan: null,
        pengalamanKerja: null,
        pendidikan: null,
        isReport: null,
      },
      formNewLender: {
        pengguna: 0,
        penyelenggara: null,
        lender: null,
        sumberDana: "",
        negaraDomisili: 0,
        kewarganegaraan: 0,
        report: null,
      },
      formNewBorrower: {
        pengguna: "",
        penyelenggara: "",
        borrower: "",
        statusKepemilikanRumah: null,
        report: null,
      },
      uploadedFile: "",
    };
  },
  // async beforeCreate() {
  //
  //   // this.formNewUser = store.state.currentUser
  // },
  async mounted() {
    await store.dispatch("options/fetchLoanApplications");

    if (this.$route.params.role === "lender")
      this.getLender(this.$route.params.id);
    else this.getBorrower(this.$route.params.id);
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-user");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    async getLender(id) {
      // await store.dispatch("office/fetchLender", id);
      // this.formNewUser = store.state.office.currentLender[0];
      await store.dispatch("office/fetchLenderForEdit", this.$route.params.id);
      this.formNewLender = {
        pengguna: store.state.office.currentLender.id_pengguna,
        penyelenggara: store.state.office.currentLender.id_penyelenggara,
        lender: store.state.office.currentLender.id_lender,
        sumberDana: store.state.office.currentLender.sumber_dana,
        kewarganegaraan: store.state.office.currentLender.id_kewarganegaraan,
        negaraDomisili: store.state.office.currentLender.id_negara_domisili,
      };
      await store
        .dispatch("office/fetchUser", this.formNewLender.pengguna)
        .then(() => {
          this.formNewUser = {
            idPenyelenggara: store.state.office.currentUser.id_penyelenggara,
            idPenggunaRebel: store.state.office.currentUser.id_pengguna_rebel,
            idPengguna: store.state.office.currentUser.id_pengguna,
            namaPengguna: store.state.office.currentUser.nama_pengguna,
            jenisPengguna: store.state.office.currentUser.jenis_pengguna,
            tanggalRegistrasi: converters.shortDateInput(
              store.state.office.currentUser.tgl_registrasi
            ),
            jenisIdentitas: store.state.office.currentUser.jenis_identitas,
            nomorIdentitas: store.state.office.currentUser.no_identitas,
            nomorNpwp: store.state.office.currentUser.no_npwp,
            jenisBadanHukum:
              store.state.office.currentUser.id_jenis_badan_hukum,
            tempatLahir: Number(store.state.office.currentUser.tempat_lahir),
            tanggalLahir: store.state.office.currentUser.tgl_lahir
              ? store.state.office.currentUser.tgl_lahir.substring(0, 10)
              : null,
            jenisKelamin: store.state.office.currentUser.id_jenis_kelamin,
            alamat: store.state.office.currentUser.alamat,
            kota: store.state.office.currentUser.id_kota,
            provinsi: store.state.office.currentUser.id_provinsi,
            kodePos: store.state.office.currentUser.kode_pos,
            agama: store.state.office.currentUser.id_agama,
            perkawinan: store.state.office.currentUser.id_status_perkawinan,
            pekerjaan: store.state.office.currentUser.id_pekerjaan,
            bidangPekerjaan: store.state.office.currentUser.id_bidang_pekerjaan,
            pekerjaanOnline: store.state.office.currentUser.id_pekerjaan_online,
            pendapatan: store.state.office.currentUser.pendapatan,
            pengalamanKerja: store.state.office.currentUser.pengalaman_kerja,
            pendidikan: store.state.office.currentUser.id_pendidikan,
          };
        });
    },
    async getBorrower(id) {
      // await store.dispatch("office/fetchBorrower", id);
      // this.formNewUser = store.state.office.currentBorrower[0];
      // console.log(this.formNewUser);
      await store.dispatch("office/fetchBorrower", this.$route.params.id);
      this.formNewBorrower = {
        pengguna: store.state.office.currentBorrower.id_pengguna,
        penyelenggara: store.state.office.currentBorrower.id_penyelenggara,
        borrower: store.state.office.currentBorrower.id_borrower,
        statusKepemilikanRumah:
          store.state.office.currentBorrower.status_kepemilikan_rumah,
      };
      await store.dispatch("office/fetchUser", this.formNewBorrower.pengguna);
      this.formNewUser = {
        idPenyelenggara: store.state.office.currentUser.id_penyelenggara,
        idPenggunaRebel: store.state.office.currentUser.id_pengguna_rebel,
        idPengguna: store.state.office.currentUser.id_pengguna,
        namaPengguna: store.state.office.currentUser.nama_pengguna,
        jenisPengguna: store.state.office.currentUser.jenis_pengguna,
        tanggalRegistrasi: converters.shortDateInput(
          store.state.office.currentUser.tgl_registrasi
        ),
        jenisIdentitas: store.state.office.currentUser.jenis_identitas,
        nomorIdentitas: store.state.office.currentUser.no_identitas,
        nomorNpwp: store.state.office.currentUser.no_npwp,
        jenisBadanHukum: store.state.office.currentUser.id_jenis_badan_hukum,
        tempatLahir: store.state.office.currentUser.tempat_lahir,
        tanggalLahir: store.state.office.currentUser.tgl_lahir.substring(0, 10),
        jenisKelamin: store.state.office.currentUser.id_jenis_kelamin,
        alamat: store.state.office.currentUser.alamat,
        kota: store.state.office.currentUser.id_kota,
        provinsi: store.state.office.currentUser.id_provinsi,
        kodePos: store.state.office.currentUser.kode_pos,
        agama: store.state.office.currentUser.id_agama,
        perkawinan: store.state.office.currentUser.id_status_perkawinan,
        pekerjaan: store.state.office.currentUser.id_pekerjaan,
        bidangPekerjaan: store.state.office.currentUser.id_bidang_pekerjaan,
        pekerjaanOnline: store.state.office.currentUser.id_pekerjaan_online,
        pendapatan: store.state.office.currentUser.pendapatan,
        pengalamanKerja: store.state.office.currentUser.pengalaman_kerja,
        pendidikan: store.state.office.currentUser.id_pendidikan,
      };
    },
    async submitUser() {
      this.uploadedFile = "";
      this.loading = true;

      // try {
      console.log(this.formNewUser);
      await store
        .dispatch("office/editUser", {
          id: this.$route.params.id,
          data: this.formNewUser,
        })
        .then((r) => {
          if (r) {
            this.loading = false;
          }
        });
      this.steps = 2;
      this.showDialog = !this.showDialog;
      // } catch (err) {
      this.showErrDialog = true;
      // }

      if (this.$route.params.role === "lender") {
        // try {
        console.log(this.formNewLender);
        this.loading = true;

        await store
          .dispatch("office/editLender", {
            id: this.$route.params.id,
            data: this.formNewLender,
          })
          .then((r) => {
            if (r) {
              this.loading = false;
            }
          });

        this.showSuccessDialog = true;
        // } catch (err) {
        this.showErrDialog = true;
        // }
      } else {
        this.loading = true;

        await store
          .dispatch("office/editBorrower", {
            id: this.$route.params.id,
            data: this.formNewBorrower,
          })
          .then((r) => {
            if (r) {
              this.loading = false;
            }
          });
      }
    },
  },
};
</script>
